import React from 'react';
import Navigation from "../../components/Navigation";
import art1 from '../../assets/art1.png'
import art2 from '../../assets/art2.png'
import art3 from '../../assets/art3.png'
import art4 from '../../assets/art4.png'
import art5 from '../../assets/art5.jpg'
import art6 from '../../assets/art6.png'
import archery1 from '../../assets/archery1.jpg'
import archery2 from '../../assets/archery2.jpg'
import archery3 from '../../assets/archery3.jpg'
import archery4 from '../../assets/archery4.jpg'
import archery5 from '../../assets/archery5.jpg'
import archery6 from '../../assets/archery6.jpg'
import Footer from "../../components/Footer";
import useScrollToTop from "../../helpers/UI/scrollToTop";
const Interest = () => {
    useScrollToTop()
    return (
        <div className={'about'}>
            <Navigation/>
            <div className="center-all ">
                <h4 className="h4 mgT8 whiteText shadowText"> Artistry</h4>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={art5} alt="" className={'fluidImg'}/>
                    </div>
                    <div className="grid-item2_4">
                        <img src={art2} alt="" className={'fluidImg'}/>
                    </div>
                </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={art3} alt="" className={'fluidImg'}/>
                    </div>
                    <div className="grid-item2_4">
                        <img src={art4} alt="" className={'fluidImg'}/>
                    </div>
                </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={art1} alt="" className={'fluidImg'}/>
                    </div>
                    <div className="grid-item2_4">
                        <img src={art6} alt="" className={'fluidImg'}/>
                    </div>
                </div>
                <div className="opq-card mgT2">
                    <p className="h6 whiteText textJust">Rotimi’s art depicts his fascination with emotions and their evocation of them. He mostly uses vintage handmade 100-
                        year-old bottles from eastern Nigeria used during the slave trade era.
                        Rotimi uses his art to advocate for mental health, an issue he has had to deal with. His art also addresses current societal
                        issues in Nigeria focusing on Women's empowerment, especially in Northern Nigeria. He also explores everyday issues,
                        particularly life, love, and relationships.
                        In a Nutshell, Rotimi uses his art as an advocacy tool, using the proceeds to pay the hospital bills of victims of conflict in
                        rural areas in Nigeria through his foundation, Humanity square foundation. He has held several successful art exhibitions.</p>
                </div>
            </div>
            <div className="center-all ">
                <h4 className="h4 mgT8 whiteText shadowText"> Archery</h4>
                <div className="opq-card mgT4">
                    <p className="h6 whiteText textJust">
                        Rotimi is a member of <span className="greenText"> Nigeria Archery Federation</span>. He won 2 gold medals in 2021 in the 30 meter Compound Bow Competition
                    </p> </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={archery1} alt="" className={'fluidImg'}/>
                    </div>
                    <div className="grid-item2_4">
                        <img src={archery2} alt="" className={'fluidImg'}/>
                    </div>
                </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={archery3} alt="" className={'fluidImg'}/>
                    </div>
                    <div className="grid-item2_4">
                        <img src={archery4} alt="" className={'fluidImg'}/>
                    </div>
                </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={archery5} alt="" className={'fluidImg'}/>
                    </div>
                    <div className="grid-item2_4">
                        <img src={archery6} alt="" className={'fluidImg'}/>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default Interest;