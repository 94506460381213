import './sass/main.scss';
import React,{useCallback,useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {UserProvider} from "./hoc/userProvider";
import Home from "./pages/Home/Home";
import {AnimatePresence} from "framer-motion";
import About from "./pages/about/about";
import Media from "./pages/media/Media";
import Business from "./pages/business/business";
import Interest from "./pages/interests/interest";
// import ProtectedRoute from "./hoc/protectedRoute";
function App() {
  const navigate = useNavigate();
  const [user,setUser] = useState(localStorage.getItem('user_id')||{});
  const [token,setToken] = useState(localStorage.getItem('user_ton')||null);

  const logOut = useCallback (()=> {
    localStorage.clear();
    navigate('/',{replace:true});
  },[navigate])
  function login(data,token) {
    setUser(data);
    setToken(token);
    localStorage.setItem('user_ton',token)
    localStorage.setItem('user_id',JSON.stringify(data));
    //navigate to dashboard
    navigate('/blog/admin',{replace:true});
  }
  return (

      <UserProvider.Provider value={{token,user,logOut,login}}>
        <AnimatePresence initial={true} mode={"wait"}>
          <Routes>
            <Route exact path={'/'} element={<Home/>}/>
            <Route exact path={'/about'} element={<About/>}/>
            <Route exact path={'/media'} element={<Media/>}/>
            <Route exact path={'/interests'} element={<Interest/>}/>
            <Route exact path={'/business-and-ventures'} element={<Business/>}/>

            <Route path={'/*'} element={<>Error</>}/>
          </Routes>
        </AnimatePresence>
      </UserProvider.Provider>
  );
}

export default App;
