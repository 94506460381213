import React from 'react';
import Navigation from "../../components/Navigation";
import hippie from '../../assets/hippie.jpg'
import google from '../../assets/google2.0.0.jpg'
import cnn from '../../assets/cnn-business.png'
import financial_times from '../../assets/financial_times.png'
import un_logo from '../../assets/un_logo.png'
import un_logo_alt from '../../assets/un_alt.png'
// import un_logo_alt_2 from '../../assets/un_alt2.png'
import legitNg from '../../assets/legit-ng.png'
import Tvc from '../../assets/TVC-Logo-1.png'
import Arise from '../../assets/ariseTv.jpg'
import Forbes from '../../assets/Forbes.png'
import Forbes2 from '../../assets/forbes_2.jpg'
import channelsTv from '../../assets/Channels_TV.jpg';
import wandieville from '../../assets/wanderville.jpg';
import agro_summit from '../../assets/business_day.jpg';
import global_farmers from '../../assets/global_farmers.jpg';
import choiceful from '../../assets/choiseful.jpg';
import pulseNg from '../../assets/pulse.png';
import nourish from '../../assets/afchub_logo_w200.png';
import corporate_farmers from '../../assets/corporate_farmers.jpg';
import theBridge from '../../assets/theBridge.png';
import riseNetwork from '../../assets/riseNetwork.png';
import startupSouth from '../../assets/startupsouth4_logo.png';
import Hague from '../../assets/hague_.png';
import self_1 from '../../assets/self_1.jpg';
import self_2 from '../../assets/self_2.jpg';
import print_med from '../../assets/Print_media.jpg';
import med_cov from '../../assets/med_cov.jpg';
import spark_ignite from '../../assets/spark_ignite.jpg';
import speak_eng from '../../assets/speak_eng.jpg';
import exi_1 from '../../assets/exi1.jpg';
import exi_2 from '../../assets/exi2.jpg';
import exi_3 from '../../assets/exi3.jpg';
import dailyTrust from '../../assets/daily-trust-logo.jpg';
import the_nation from '../../assets/the_nation.jpg';
import inspiration_fm from '../../assets/inspiration_fm.png';
import business_day from '../../assets/business_day2.jpg';
import BBC from '../../assets/BBC-Logo-1.png';
import KnowledgeBandits from '../../assets/knowledgeBandits.png';
import Cambridge from '../../assets/Cambridge_Judge_Business_School_(logo).png';
import Cornell from '../../assets/Cornell-Alliance.png';
import Footer from "../../components/Footer";
import useScrollToTop from "../../helpers/UI/scrollToTop";
const Media = () => {
    useScrollToTop()
    return (
        <div  className={'about'}>
           <Navigation/>
            <div className="mgT8 center-all">
                <h4 className="h4 whiteText">MEDIA COVERAGE INTERVIEWS</h4>
            </div>
            <div className="center-all">
                <MediaCard left={<>
                    <h6 className="h6 greenText"> FORBES </h6>
                    <p className="h7 mgT1 lightColor">2016</p>
                    <div className="whiteCard mgT2">

                        <img src={Forbes} alt="" className="fluidImg mgT2"/>

                    </div>
                </>} right={<><a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer" href={'https://www.forbes.com/sites/mfonobongnsehe/2016/06/27/meet-the-36-year-old-entrepreneur-who-owns-nigerias-2nd-largest-rice-farm/?sh=37d490f74dd6'}  className={'h6 whiteText no-deco'}> Meet The 35-Year-Old Entrepreneur Who Owns Nigeria's 2nd Largest Rice Farm</a>
                    {/*<img src={Forbes2} alt="" className="fluidImg mgT2"/>*/}
                </>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">CNN BUSINESS</h6>
                    <p className="h7 mgT1 lightColor">2020</p>

                    <img src={cnn} alt="" className="fluidImg mgT2"/>
                </>

                }
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://edition.cnn.com/videos/business/2020/12/18/marketplace-africa-nigeria-rotimi-williams-kereksuk-rice-spc.cnn'}  className={'h6 whiteText no-deco'}>How a banker-turned-farmer built the second largest rice farm in Nigeria. CNN's
                               Eleni Giokos caught up with Kereksuk Rice Farm CEO Rotimi Williams to explore how a new generation of
                               Nigerian farmers is shaping the country's agricultural future</a>}/>
                <MediaCard left={<>
                    <h6 className="h6 greenText"> BBC</h6>
                    <p className="h7 mgT1 lightColor">2016</p>

                    <div className="whiteCard mgT2"><img src={BBC} alt="" className="fluidImg mgT2"/></div>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://www.bbc.com/news/world-africa-37021044'}  className={'h6 whiteText no-deco'}>Nigeria's deadly battle for land: Herdsmen v farmers.</a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText"> Financial Times</h6>
                    <p className="h7 mgT1 lightColor">2020</p>

                    <img src={financial_times} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://www.ft.com/content/fc0934a8-e394-11e9-9743-db5a370481bc'}  className={'h6 whiteText no-deco'}>Conflict over fertile lands threatens Nigeria’s food security; A deadly ethnic battle
                               sparked by cattle-herders is driving farmers from their land.</a>}/>


                <MediaCard left={<>
                    <h6 className="h6 greenText"> Business Day </h6>
                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={business_day} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer" href={'https://businessday.ng/agriculture/article/resolute-4-0-seen-as-solution-to-nigerias-farmers-herders-crisis/'}  className={'h6 whiteText no-deco'}> Interview with Josephine Okojie of business day magazine on Resolute 4.0 seen as a solution to the farmer-herder crisis</a>}/>


                <MediaCard left={<>
                    <h6 className="h6 greenText">Google for Nigeria</h6>
                    <p className="h7 mgT1 lightColor">2018</p>
                    <img src={google} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.youtube.com/watch?v=vyu0J84SZuw'}  className={'h6 whiteText no-deco'}> Celebrate your Hustle</a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText"> Wandieville Media interview  </h6>
                    <p className="h7 mgT1 lightColor">2018</p>

                    <img src={wandieville} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer" href={'https://www.youtube.com/watch?v=ixmy6a8fW_8'}  className={'h6 whiteText no-deco'}> Wandie Kazeem interviewing Rotimi Williams, Founder of Kereksuk Rice Farm</a>}/>






                <MediaCard left={<>
                    <h6 className="h6 greenText">Hippie In a Suit</h6>
                    <p className="h7 mgT1 lightColor">August 2021</p>
                    <img src={hippie} alt="" className="fluidImg mgT2"/>
                </>

                }
                right={<a target={'_blank'} referrerPolicy={'no-referrer'}
                   href={'https://www.listennotes.com/es/podcasts/hippie-in-a-suit/s1-e9-rotimi-williams-ysZX0JzfNWI/'} rel="noreferrer"  className={'h6 whiteText no-deco'}>Podcast Interview. Rotimi Williams is a former banker and journalist, turned rice farmer, tech entrepreneur and artist</a>}/>



                <MediaCard left={<>
                    <h6 className="h6 greenText">Interview on the Public Eye with Funnmi Iyanda </h6>
                    <p className="h7 mgT1 lightColor">2021</p></>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'}
                                     href={'https://www.youtube.com/watch?v=TRlA7ibCxiA'} rel="noreferrer"  className={'h6 whiteText no-deco'}>The future of Agriculture in Nigeria</a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">Legit. ng</h6>
                    <p className="h7 mgT1 lightColor">2020</p>
                    <img src={legitNg} alt="" className="fluidImg mgT2"/>
                </>}
                right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                   href={'https://www.legit.ng/1152253-rotimi-williams-39-year-nigerian-owns-2nd-largest-rice-farm-country.html'}  className={'h6 whiteText no-deco'}>Rotimi Williams: 39-year-old Nigerian who owns 2nd largest rice farm in the country </a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText"> The Spark Magazine</h6>
                    <p className="h7 mgT1 lightColor">2018</p></>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer" href={'https://www.thesparkng.com/hub/rotimi-williams-empowers-agro-entrepreneur/'}  className={'h6 whiteText no-deco'}> Rotimi Williams Empowers an Agro-Entrepreneur with NGN0.2 Million</a>}/>


                <MediaCard left={<>
                    <h6 className="h6 greenText">Interview With Channels TV</h6>
                    <p className="h7 mgT1 lightColor">2020</p>
                    <img src={channelsTv} alt="" className="fluidImg mgT2"/>
                </>}
                right={<span
                     className={'h6 whiteText no-deco'}>Conflict Resolution</span>}/>



                <MediaCard left={<>
                    <h6 className="h6 greenText">  The Morning Show on Arise TV</h6>
                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={Arise} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<span   className={'h6 whiteText no-deco'}> The state of the Agric Sector in Nigeria</span>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">  Aim Higher Africa Ignite your passion series</h6>
                    <p className="h7 mgT1 lightColor">2019</p></>}
                           right={<span   className={'h6 whiteText no-deco'}> Newspaper feature “Be a non-conformist when it comes to
                business” _Rotimi Williams</span>}/>
               <MediaCard left={<>
                    <h6 className="h6 greenText"> Meet The Farmers Conference </h6>
                    <p className="h7 mgT1 lightColor">2018</p></>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://mtfc.crenov8.com/news/agripreneur-of-the-week-rotimi-williams-of-kereksuk-rice-farm/'}  className={'h6 whiteText no-deco'}> Agripreneur Of The Week - Rotimi Williams of Kereksuk Rice Farm</a>}/>

               <MediaCard left={<>
                    <h6 className="h6 greenText"> The Spark Magazine</h6>
                    <p className="h7 mgT1 lightColor">2018</p>

                   <img src={spark_ignite} alt="" className="fluidImg mgT2"/>
               </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.thesparkng.com/hub/the-green-lantern-rotimi-williams-building-a-formidable-agro-conglomerate-%EF%BB%BF/'}  className={'h6 whiteText no-deco'}>THE GREEN LANTERN - Rotimi Williams, Building A Formidable Agro-Conglomerate</a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText"> All Access Show</h6>
                    <p className="h7 mgT1 lightColor">2018</p></>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.youtube.com/watch?v=SB_q-muoy_E'}  className={'h6 whiteText no-deco'}>All Access Show</a>}/>


                <MediaCard left={<>
                    <h6 className="h6 greenText">  The Morning Show on Arise TV</h6>
                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={Arise} alt="" className="fluidImg mgT2"/>
                </>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">Interview with Knowledge Bandits
                    </h6>
                    <p className="h7 mgT1 lightColor">2018</p>
                    <img src={KnowledgeBandits} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://soundcloud.com/knowledgebandits/episode-36-what-does-it-take-to-run-successful-agribusiness-ventures-with-rotimi-williams?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing'}  className={'h6 whiteText no-deco'}> What Does It Take To Run Successful Agribusiness Ventures with Rotimi Williams</a>}
                />

             <MediaCard left={<>
                    <h6 className="h6 greenText">Aim Higher Africa  </h6>
                    <p className="h7 mgT1 lightColor">2017</p></>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.youtube.com/watch?v=uBFaNmmxVSk'}
                                     className={'h6 whiteText no-deco'}>Ignite series Interview</a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">Daily trust Agric Revolution Series Farming out of Poverty
                    </h6>
                    <p className="h7 mgT1 lightColor">2015</p>

                    <img src={dailyTrust} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://dailytrust.com/nigeria-depended-on-imported-rice-for-too-long'}
                                     className={'h6 whiteText no-deco'}>‘Nigeria Depended On Imported Rice For Too Long’</a>}
                        />


                <MediaCard left={<>
                    <h6 className="h6 greenText"> TVC Breakfast show </h6>
                    <p className="h7 mgT1 lightColor">2016</p>

               <img src={Tvc} alt="" className="fluidImg mgT2"/>

            </>} right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.youtube.com/watch?v=95DTmyS-S10'}  className={'h6 whiteText no-deco'}> Nigeria's Rice Masterplan</a>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">Inspiration FM
                    </h6>
                    <img src={inspiration_fm} alt="" className="fluidImg mgT2"/>
                    {/*<p className="h7 mgT1 lightColor">2018</p>*/}
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">The Nation Newspaper
                    </h6>
                    <img src={the_nation} alt="" className="fluidImg mgT2"/>
                    {/*<p className="h7 mgT1 lightColor">2018</p>*/}
                </>}
                   right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://thenationonlineng.net/lagos-boy-turns-nasarawa-rice-farmer/'}  className={'h6 whiteText no-deco'}>Lagos ‘boy’ turns Nasarawa rice farmer</a>}
                />

            <div className="grid">
               <div className="grid-item2_4">
                   <img src={med_cov} className={'fluidImg'} alt="media coverage"/>
               </div>
               <div className="grid-item2_4">
                   <img src={agro_summit} className={'fluidImg'} alt="media coverage"/>
               </div>
            </div>
            </div>
            <div className="mgT8 center-all">
                <h4 className="h4 whiteText">SPEAKING ENGAGEMENTS</h4>
            </div>
            <div className="center-all">
                <MediaCard left={<>
                    <h6 className="h6 greenText">  UN Conference  </h6>
                    <p className="h7 mgT1 lightColor">2017</p>

                    <img src={un_logo} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<span   className={'h6 whiteText no-deco'}>Nigerian Representative</span>}/>
                <MediaCard left={<>
                    <h6 className="h6 greenText">Speaker at the UN Youth Entrepreneurship and Self Employment Forum Darkar </h6>
                    <p className="h7 mgT1 lightColor">2017</p>
                    <img src={un_logo_alt} alt="" className="fluidImg mgT2"/>


                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">Speaker/Nigerian Representative UN Europe-Africa Summit, Vienna – Agricultural Digitalization and Conflict Resolution</h6>
                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={un_logo_alt} alt="" className="fluidImg mgT2"/>


                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">UN EU HIGH LEVEL SUMMIT</h6>
                    <p className="h7 mgT1 lightColor">2018</p>
                    {/*<img src={un_logo_alt_2} alt="" className="fluidImg mgT2"/>*/}
                    <img src={speak_eng} className={'fluidImg'} alt="media coverage"/>

                </>}

                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://sustainabledevelopment.un.org/hlpf/2018'}  className={'h6 whiteText no-deco'}> HIGH-LEVEL POLITICAL FORUM 2018</a>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">Nigerian Economic Diplomacy Initiative, UK Ministry of Foreign Affairs
                    </h6>
                    <p className="h7 mgT1 lightColor">2018</p>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">  The Hague Humanity Hub </h6>
                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={Hague} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.humanityhub.net/calendar/whats-hub-lunch-with-rotimi-williams-of-resolute-4-0/'}
                                     className={'h6 whiteText no-deco'}> The Humanity Hub is a not-for-profit foundation that supports and strengthens the Hague ecosystem for
                               a more peaceful and just world</a>}/>
                <MediaCard left={<>
                    <h6 className="h6 greenText">  Cambridge Judge Business School  </h6>
                    <p className="h7 mgT1 lightColor">2017</p>
                    <img src={Cambridge} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://www.movemeback.com/events/cambridge-africa-business-network-conference-2017/'}  className={'h6 whiteText no-deco'}> Key panelist at the Cambridge Africa Business Network Conference</a>}/>


                <MediaCard left={<>
                    <h6 className="h6 greenText"> Speaker at Business Unusual Webinar by Surge Africa - Africa and Food Security
                    </h6>
                    <p className="h7 mgT1 lightColor">2020</p>


                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText"> Speaker at Google for Nigeria
                    </h6>
                    <p className="h7 mgT1 lightColor">2017</p>
                    <img src={google} alt="" className="fluidImg mgT2"/>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText"> Speaker The Platform Nigeria; Restoring Nigeria Economy Through Agriculture; Current realities and future possibilities
                    </h6>
                    <p className="h7 mgT1 lightColor">2020</p>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText"> SWORTE TALKS ; Key Panellist Let’s Get Innovative
                    </h6>
                    <p className="h7 mgT1 lightColor">2017</p>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText"> Speaker TCV News Summit on Conflict Resolution
                    </h6>
                    <img src={Tvc} alt="" className="fluidImg mgT2"/>
                    {/*<p className="h7 mgT1 lightColor">2017</p>*/}
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">Rise Network Lagos Youth Week; Speaker, Mega Youth Conference
                    </h6>
                    <p className="h7 mgT1 lightColor">2018</p>
                    <img src={riseNetwork} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://risenetworks.org/lagos-youth-week-mega-youth-conference-organised-by-rise-networks-in-partnership-with-the-lagos-state-government-in-commemoration-of-the-un-international-youth-day-2018/'}  className={'h6 whiteText no-deco'}> Key panelist at the Cambridge Africa Business Network Conference</a>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">Speaker at Start Up South 4; Scaling and Funding Agriculture, Owerri
                    </h6>
                    <img src={startupSouth} alt="" className="fluidImg mgT2"/>
                </>}

                           right={<a target={'_blank'} rel="noreferrer" referrerPolicy={'no-referrer'} href={'https://4.startupsouth.org/speakers/rotimi-williams/'}  className={'h6 whiteText no-deco'}>Speaker at Start Up South 4</a>}
                />

            </div>
            <div className="mgT8 center-all">
                <h4 className="h4 whiteText">AWARDS &
                    RECOGNITIONS</h4>
            </div>
            <div className="center-all">
                <MediaCard left={<>
                    <h6 className="h6 whiteText">AGRIHUB ; Certificate of Excellence in recognition of your contribution toward the development of the
                        Nigerian Agricultural sector
                    </h6>

                    <p className="h7 mgT1 lightColor">2017</p>
                    {/*<img src={agrihub} alt="" className="fluidImg mgT2"/>*/}
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 whiteText">FORBES AFRIQUE; TOP 100 UNDER 40 2018 – NUMBER 26
                    </h6>

                    <p className="h7 mgT1 lightColor">2018</p>
                    <img src={Forbes2} alt="" className="fluidImg mgT2"/>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 whiteText">CHOISEUL; Number 76, 100 Africa Economic Leaders for tomorrow
                    </h6>

                    <p className="h7 mgT1 lightColor">2020</p>
                    <img src={choiceful} alt="" className="fluidImg mgT2"/>
                </>}
                />

                <MediaCard left={<>
                    <h6 className="h6 whiteText">MEMBER Global Farmer Network 2020, Mexico
                    </h6>
                    <p className="h7 mgT1 lightColor">2020</p>
                    <img src={global_farmers} alt="" className="fluidImg mgT2"/>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 whiteText">Cornell University Alliance For Science; Certificate of Completion of the Cornell Alliance for Science Farmer
                        Leadership Training Course, Mexico City 2020

                    </h6>

                    <p className="h7 mgT1 lightColor">2020</p>
                    <img src={Cornell} alt="" className="fluidImg mgT2"/>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 whiteText">Corporate Farmers International; Nigeria Top 15 Corporate Farmers 2022

                    </h6>

                    <p className="h7 mgT1 lightColor">2022</p>
                    <img src={corporate_farmers} alt="" className="fluidImg mgT2"/>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 whiteText">Pulse Nigeria Feature 2019; Young and Getting it

                    </h6>

                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={pulseNg} alt="" className="fluidImg mgT2"/>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 whiteText">Henshaw Capital Certificate of Participation (2009); Nigerian National Private Equity Initiative; Funds Manager Seminar on Private Equity Fund
                        Registration

                    </h6>

                    <p className="h7 mgT1 lightColor">2009</p>
                </>}
                />
                <MediaCard left={<>
                    <h6 className="h6 greenText">  Special Recognition by The Bridge Leadership Foundation (2018)  </h6>
                    <p className="h7 mgT1 lightColor">2018</p>
                   <div className="whiteCard">
                       <img src={theBridge} alt="" className="fluidImg mgT2"/>
                   </div>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'}  rel="noreferrer"href={'https://thebridgeleadership.org/special-recognition-rotimi-williams/'}  className={'h6 whiteText no-deco'}> Special Recognition by The Bridge Leadership Foundation </a>}/>
                <MediaCard left={<>
                    <h6 className="h6 greenText">   Nourishing Africa (2019) </h6>
                    <p className="h7 mgT1 lightColor">2019</p>
                    <img src={nourish} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer" href={'https://afchub.org/entrepreneurs/rotimi-williams-08197'}  className={'h6 whiteText no-deco'}> Member Agri-Food Entrepreneurs </a>}/>
                <MediaCard left={<>
                    <h6 className="h6 greenText">Hippie In a Suit</h6>
                    <p className="h7 mgT1 lightColor">August 2021</p>
                    <img src={hippie} alt="" className="fluidImg mgT2"/>
                </>

                }
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://www.listennotes.com/es/podcasts/hippie-in-a-suit/s1-e9-rotimi-williams-ysZX0JzfNWI/'}  className={'h6 whiteText no-deco'}>Podcast Interview. Rotimi Williams is a former banker and journalist, turned rice farmer, tech entrepreneur and artist</a>}/>
            </div>


            <div className="mgT8 center-all">
                <h4 className="h4 whiteText">SELF WRITTEN ARTICLES</h4>
            </div>
            <div className="center-all">
                <MediaCard left={<>
                    <h6 className="h6 greenText">A Short Whitepaper on understanding the dynamics of communal conflicts in Nigeria and the effect of the Blanket labeling as “Farmer — Herder crisis</h6>
                    <p className="h7 mgT1 lightColor">Oct 2019</p>
                    <img src={self_2} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<><a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://medium.com/@rotimi_50551/a-short-whitepaper-on-understanding-the-dynamics-of-communal-conflicts-in-nigeria-and-the-effect-bbf9091fb7ef'}  className={'h6 whiteText no-deco'}> The case study is based on the
                               understanding of the dynamic issues in Bassa LGA, Plateau state.</a>

                             <div className="width45">

                             </div>
                           </>}/>

                <MediaCard left={<>
                    <h6 className="h6 greenText">The Role of Communal Clashes in Rural to Urban Migration in Nigeria.
                    </h6>
                    <p className="h7 mgT1 lightColor">Nov 2019</p>

                    <img src={self_1} alt="" className="fluidImg mgT2"/>
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://medium.com/@rotimi_50551/the-role-of-communal-clashes-in-rural-to-urban-migration-in-nigeria-d77a94749334'}  className={'h6 whiteText no-deco'}>Read Here</a>}/>
                <MediaCard left={<>
                    <h6 className="h6 greenText"> The Economics of Prostitution
                    </h6>
                    <p className="h7 mgT1 lightColor">Nov 2019</p>
                    {/*<img src={hippie} alt="" className="fluidImg mgT2"/>*/}
                </>}
                           right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                     href={'https://medium.com/@rotimi_50551/the-economics-of-prostitution-e898ea101210'}  className={'h6 whiteText no-deco'}>Read Here</a>}/>



            </div>
            <div className="mgT8 center-all">
                <h4 className="h4 whiteText">PRINT MEDIA</h4>
            </div>
                <div className="center-all">

                    <MediaCard left={<>
                        <h6 className="h6 greenText"> BUSINESS DAY ; PROPOSED SOLUTION TO THE NIGERIAN STOCK MARKET AND BANKING CRISIS.


                        </h6>
                        <p className="h7 mgT1 lightColor">2009</p>
                        <img src={print_med} alt="" className="fluidImg mgT2"/>
                    </>}
                               right={<span  className={'h6 whiteText no-deco'}>THIS ARTICLE WAS WRITTEN AT A TIME WHEN THE NIGERIAN BANKING SECTOR
                                   WAS FACED WITH A CRISIS AND IN NEED OF STABILIZING AND RESTRUCTURING.
                                   ROTIMI IN THIS ARTICLE, CAPTURED THESE PROBLEMS, PROFFERING SOLUTIONS. ONE
                                   OF WHICH WAS THE NEED FOR REGULATION IN RESOLVING THE ISSUE OF NONPERFORMING LOAN ASSETS IN THE BANKING SECTOR. THIS ARTICLE SERVED AS A
                                   STRUCTURE/MODEL FOR THE CREATION OF WHAT IS NOW KNOWN AS
                                   AMCON(ASSET MANAGEMENT CORPORATION OF NIGERIA)</span>}/>


                    <MediaCard left={<>
                        <h6 className="h6 greenText">THE SPARK; ARTICLE ON RESOLUTE 4.0


                        </h6>
                        <p className="h7 mgT1 lightColor">2020</p>
                        {/*<img src={hippie} alt="" className="fluidImg mgT2"/>*/}
                    </>}
                               right={<a target={'_blank'} referrerPolicy={'no-referrer'} rel="noreferrer"
                                         href={'https://www.thesparkng.com/hub/RESOLUTE-4-0/'}  className={'h6 whiteText no-deco'}>Read Here</a>}/>
                </div>
            <div className="mgT8 center-all">
                <h4 className="h4 whiteText">ART EXHIBITION/RECOGNITION</h4>
            </div>

            <div className="center-all">
                <div className="opq-card">
                    <ul className={'list'}>
                        <li className={'h6 whiteText mgT1'}>SAO AND THE MUSE 2 2017 Art Exhibition at the SAO CAFÉ Lagos
                           </li>
                        <li className={'h6 whiteText mgT1'}> SAO AND THE MUSE 3; Collaboration Era 2018</li>
                        <li className={'h6 whiteText mgT1'}>ARTYRAMA; Ode to mankind Art Exhibition 2018</li>
                        <li className={'h6 whiteText mgT1'}>Art String Article; Exploring African Art 2018</li>
                        <li className={'h6 whiteText mgT1'}>un-TOLD Contemporary Art Exhibition with Retro Africa 2020</li>
                        <li className={'h6 whiteText mgT1'}>Leadership Newspaper Article; Need to Increase Visibility of Northern Culture in Contemporary Art (Inspired by Artwork
                            presented at the Retro Africa Exhibition)</li>
                    </ul>
                </div>
               <div className="grid">
                    <div className="grid-item1_3">

                        <img src={exi_1} alt="" className="fluidImg mgT2"/>
                    </div>
                    <div className="grid-item1_3">

                        <img src={exi_2} alt="" className="fluidImg mgT2"/>
                    </div>
                    <div className="grid-item1_3">

                        <img src={exi_3} alt="" className="fluidImg mgT2"/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};


function MediaCard({left,right}) {
    return <div className={'mediaCard grid'}>
            <div className="grid-item1_4">{left}</div>
            <div className="grid-item3_4">{right}</div>
    </div>
}

export default Media;