import React,{useEffect, useRef, useState} from 'react';
import '../sass/components/nav.scss'
import Logo from '../assets/logo.svg';
import {Link} from 'react-router-dom';
import {useLocation} from "react-router-dom";
import {motion} from "framer-motion";
import menuClosed from "../assets/menu_closed.svg";
import menuOpen from "../assets/menu_open.svg";

const Navigation = () => {
    const location = useLocation();
    const [showMenu,setShowMenu] = useState(false);
    const [width,setWidth] = useState(window.innerWidth);
    const menu = useRef(null);
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    useEffect(()=>{
        function onReduce (){
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize',onReduce)
        return ()=>{
            window.removeEventListener('resize',onReduce)
        }
    },[setWidth])
    function toggleMenu() {
        setShowMenu(!showMenu)
        if(showMenu===false){
            menu.current.style.display ="flex"
        }else{
            menu.current.style.display ="none"
        }
    }
    useEffect(()=>{
        if(width>904){
            setShowMenu(true)
            menu.current.style.display ="flex"
        }else{
            setShowMenu(false)
            menu.current.style.display ="none"
        }
    },[width])
    return (
        <nav className={'navigation'} >
            <figure>
               <Link to={'/'} className={'no-deco'}> <img alt={'Rotimi Williams'} src={Logo} className={'logo'}/></Link>
            </figure>
            {width<904? <motion.span transition={transition}  onClick={toggleMenu} style={{zIndex:101,width:'4rem'}}>
                <motion.img transition={transition} style={{width:'3.5rem'}} src={showMenu===true?menuClosed:menuOpen} alt=""/>
            </motion.span>:null}
            <ul className={'navigation-list'} ref={menu}>
                <li className="navigation-list-item"><Link to={'/'}>Home</Link></li>
                <li className="navigation-list-item"><Link to={'/business-and-ventures'} className={'nowhitespace'}>Business & Ventures</Link></li>


                <li className="navigation-list-item">

                    <Link to={'/media'}>
                        Media
                    </Link>
                </li>
                <li className="navigation-list-item">

                    <Link to={'/interests'}>
                        Interests
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;