import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import aboutText  from "../../assets/aboutText.png"
import {motion} from "framer-motion";
import menuOpen from '../../assets/menu_open.svg'
import menuClosed from '../../assets/menu_closed.svg'
import Moment from "react-moment";
import { Link as ScrollLink} from "react-scroll";
import Logo from '../../assets/logo.svg'
import pointerDown from '../../assets/pointer.svg'

import un_logo from '../../assets/un_logo.png'
import channels from '../../assets/channels.png'
import Forbes from '../../assets/forbes-logo-black-and-white.png'
import cnn from '../../assets/cnn-business.png'
import bbc from '../../assets/bbc_white.png'
import ft from '../../assets/ft.png'
import cjb from '../../assets/Cambridge_Judge_Business_School_(logo).png'
import google from '../../assets/google.png'
const Hero = () => {
    const [showMenu,setShowMenu] = useState(false);
    const [width,setWidth] = useState(window.innerWidth);
    const menu = useRef(null);
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    useEffect(()=>{
        function onReduce (){
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize',onReduce)
        return ()=>{
            window.removeEventListener('resize',onReduce)
        }
    },[setWidth])
    const date_ = Date.now();
    function toggleMenu() {
        setShowMenu(!showMenu)
        if(showMenu===false){
            menu.current.style.display ="flex"
        }else{
            menu.current.style.display ="none"
        }
    }
    useEffect(()=>{
        if(width>904){
            setShowMenu(true)
            menu.current.style.display ="flex"
        }else{
            setShowMenu(false)
            menu.current.style.display ="none"
        }
    },[width])
    return (
        <header className="hero">
            <nav className="top-nav">
                <figure>
                    <Link to={'/'} className={'no-deco'}> <img alt={'Rotimi Williams'} src={Logo} className={'logo'}/></Link>
                </figure>
                {width<904? <motion.span transition={transition} className={'mob_menu'}  onClick={toggleMenu} style={{zIndex:101,width:'4rem'}}>
                    <motion.img transition={transition} style={{width:'4.5rem'}} src={showMenu===true?menuClosed:menuOpen} alt=""/>
                </motion.span>:null}
            </nav>

            {/*<motion.div initial={{x:100}} animate={{x:0}} transition={transition} className="hero-center">*/}
            {/*    */}
            {/*</motion.div>*/}
            <div className="nextBtn">
                <motion.span  >
                    <ScrollLink
                        to="last"
                        smooth={true}
                        duration={350}
                    >
                        <motion.img  whileHover={{scale:1.1,y:20}} transition={transition} alt="pointer down" src={pointerDown} className="nextBtnIcon"/>

                    </ScrollLink>
                </motion.span>
            </div>
            <nav className="bottom-nav">
                <div className="bottom-nav-top">
                    <div className="bottom-nav-left">
                        <div className="datebox">
                            <p className="dateboxDay"><Moment format={'D'}>{date_}</Moment></p>
                            <div className="mgL1">
                                <p className="dateboxMonth"><Moment format={'MMM'}>{date_}</Moment></p>
                                <p className="dateboxYear"><Moment format={'YYYY'}>{date_}</Moment></p>

                            </div>
                        </div>
                        <p className="h7 width45 whiteText mgT1 shadowText">“To be a farmer is to be a student forever, for each day brings something new.”
                            ― John Connell</p>

                    </div>

                    <ul className="nav" ref={menu}>
                        <li className="nav-item">
                            <Link to={'/'} className={'shadowText'}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/business-and-ventures'} className={'shadowText'}>Business & Ventures </Link>
                        </li>

                        <li className="nav-item">
                            <Link to={'/media'} className={'shadowText'} >Media</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/interests'} className={'shadowText'}>Interests</Link>
                        </li>
                    </ul>
                </div>
                <div className="bottom-nav-about">
                    <div className="grid-item1_4 centerText">
                        <img src={aboutText} className={'bottom-nav-about-img'} alt=""/>
                    </div>
                    <div className="grid-item2_3">
                        <p className="h7 whiteText textJust">
                            A self-taught and self-made farmer, Rotimi Williams is the founder and CEO of kereksuk rice farms and is passionate about contributing to ending world hunger. Starting off with a banking background, Rotimi quit the financial sector with the sole aim of improving the agricultural sector and contributing to food security.
                        </p>
                        <Link className="btn btn-primary mgT2 no-deco" to={'/about'}>About
                        </Link>
                    </div>
                </div>
                <div className=" opq-card">
                    <h4 className="h5 textCenter lightColor">Featured</h4>
                    <div id={'last'} className="custom_grid  mgT4">
                        <div className="textCenter">
                            <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://sustainabledevelopment.un.org/hlpf/2018'}>
                                <img src={un_logo} alt={'Rotimi\'s Appearance  '} className={'fluidImg'}/>
                            </a>
                        </div>
                        <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://www.forbes.com/sites/mfonobongnsehe/2016/06/27/meet-the-36-year-old-entrepreneur-who-owns-nigerias-2nd-largest-rice-farm/?sh=37d490f74dd6'}>
                            <img src={Forbes} alt={'Rotimi\'s Appearance on Forbes '} className={'fluidImg'}/>
                        </a>
                        <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://edition.cnn.com/videos/business/2020/12/18/marketplace-africa-nigeria-rotimi-williams-kereksuk-rice-spc.cnn'}>
                            <img src={cnn} alt={'Rotimi\'s Appearance on CNN Business'} className={'fluidImg'}/>
                        </a>
                        <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://www.bbc.com/news/world-africa-37021044'}>
                            <img src={bbc} alt={'Rotimi\'s Appearance on BBC'} className={'fluidImg'}/>
                        </a>
                        <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://www.ft.com/content/fc0934a8-e394-11e9-9743-db5a370481bc'}>

                                <img src={ft} alt={'Rotimi\'s Appearance on Financial Times '} className={'fluidImg'}/>
                        </a>
                        <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://www.movemeback.com/events/cambridge-africa-business-network-conference-2017/'}>
                            <img src={cjb} alt={'Rotimi\'s Appearance on Cambridge Judge '} className={'fluidImg'}/>
                        </a>
                        <a referrerPolicy={'no-referrer'} target={'_blank'} rel="noreferrer" className="no-deco" href={'https://www.youtube.com/watch?v=vyu0J84SZuw'}>
                            <img src={google} alt={'Rotimi\'s Appearance on Google Africa '} className={'fluidImg'}/>
                        </a>
                        <div>
                            <img src={channels} alt={'Rotimi\'s Appearance on Channels '} className={'fluidImg'}/>
                        </div>
                    </div>
                </div>
            </nav>

        </header>
    );
};

export default Hero;