import React from 'react';
import Navigation from "../../components/Navigation";
import humanity_square from '../../assets/humanity_square.JPG'
import ethan1 from '../../assets/ethan_harriet1.jpg'
import ethan2 from '../../assets/ethan_harriet2.webp'
import ethan3 from '../../assets/ethan 1-1000x1000.jpg'
import kereksuk from '../../assets/IMG_7466.JPG'
import yorfan from '../../assets/yorfanlogo.JPG'
import Footer from "../../components/Footer";
import RiceFarm from "../../assets/yorfan-obj.png";
import Resolute from "../../assets/resolute_logo.png";
import Resolute2 from "../../assets/resol1.jpg";
import Resolute3 from "../../assets/resol2.jpg";
import Foop from "../../assets/foop.jpg";
import Foop2 from "../../assets/foop2.jpg";
import useScrollToTop from "../../helpers/UI/scrollToTop";
//EU HIGH LEVEL SUMMIT
// https://sustainabledevelopment.un.org/hlpf/2018
const Business = () => {
    useScrollToTop()
    return (
        <div className={'about'}>
            <Navigation/>
            <div className="center-all ">
                <h4 className="h4 mgT8 whiteText"> BUSINESS & VENTURES</h4>
                <div className="opq-card  mgT4">
                    <div className="grid">
                        <div className="grid-item1_3">
                            <h5 className="h5 greenText">KEREKSUK RICE FARMS</h5>
                            <img alt={'kereksuk Rice Farm'} className="fluidImg mgT2" src={RiceFarm}/>
                            <img alt={'kereksuk '} className="fluidImg mgT2" src={kereksuk}/>
                        </div>
                        <div className="grid-item2_3">
                            <p className="h6 whiteText textJust">
                                Kereksuk Rice Farm is the second largest commercial rice farm in Nigeria by
                                land size and is situated in Nasarawa state, Northern Nigeria. Kereksuk Rice
                                Farm started in 2012, with 45,000 hectares leased primarily for rice paddy
                                production.
                                Formally Established in 2015, (Traded under Kereksuk Investment LTD and
                                subsequently Heritage AgriFin since 2013) Kereksuk Rice Farms Limited was
                                the first rice farm, fully focusing on the production of quality paddy to supply
                                to nearby mills, while building capacity with a mission to engage the entire
                                community in all operations, either directly or indirectly.
                                This had been
                                demonstrated by activities, focused mainly on utilizing local labor over
                                mechanization. So far, approximately 7,000 hectares of the total hectarage
                                available have been developed and are cultivated twice a year (dry and wet
                                season), with minimum yields of 4 tons per hectare in the dry season and 3.5
                                tons in the rainy season. <a target={'_blank'} rel="noreferrer" className={'link'} referrerPolicy={'no-referrer'} href="https://www.kereksuk.com">www.kereksuk.com</a>
                            </p>
                        </div>
                    </div>
                    <div className="grid mgT4">
                        <div className="grid-item1_3">
                            <h5 className="h5 greenText">RESOLUTE 4.0</h5>
                           <div className="whiteCard mgT2">
                               <img alt={'kereksuk '} className="fluidImg mgT2" src={Resolute}/>
                           </div>
                            <img alt={'kereksuk '} className="fluidImg mgT2" src={Resolute2}/>
                        </div>
                        <div className="grid-item2_3">
                            <p className="h6 whiteText textJust">Historically, sporadic acts of violence between Cattle-Herders and Farmers have
                                been a frequent occurrence in Nigeria, particularly in the Northern part, but are
                                now, more rampant across the country. These clashes have been increasing with
                                time and attacks on both the Fulani communities and farming communities are
                                now reaching a crisis point. In 2017 alone, an estimated 549 people were killed in
                                this violence, and thousands were displaced. These clashes have claimed more
                                lives than both the Boko Haram and Niger-Delta Militants. This instability is a
                                threat to food security, economic development, and human welfare in some of
                                Nigeria’s most vulnerable populations.
                                Founded in 2018, Resolute4.0 aims to foster cooperation and peaceful
                                coexistence between Farmers and Cattle-Herders in Nigeria and across West
                                Africa through the adaptation of innovative technology solutions specifically
                                designed to address these challenges.
                              <span className={'display_block'}>
                                    Website: <a href="https://resolute40.com/" rel="noreferrer" className="link" target={'_blank'} referrerPolicy={'no-referrer'}>resolute40</a>
                              </span>
                                <span className={'display_block'}>Address: <span className={'greenText'}>42 Montgomery Road First-floor Yaba, Lagos 100232, NG</span></span>
                            </p>
                        </div>
                    </div>
                    <div className="mgT4">
                        <div className="grid mgT2">
                            <div className="grid-item1_3">
                                <h5 className="h5 greenText">ETHAN AND
                                    HARRIET (CO FOUNDER)</h5>
                              <div className="grid">
                                 <div className="grid-item2_4">
                                     <img src={ethan1} alt="" className="fluidImg mgT2"/>
                                 </div>
                                 <div className="grid-item2_4">
                                     <img src={ethan3} alt="" className="fluidImg mgT2"/>
                                 </div>

                              </div>
                            </div>
                            <div className="grid-item2_3">
                                <p className="h6 whiteText mgT8 textJust">Ethan & Harriet is Nigeria’s leading Educational Toy provider to reinforce Nigeria’s history and heritage to children through fun and play.
                                    Slogan <span className={'greenText'}>"Knowledge Through Play”</span>.
                                    Ethan & Harriet designs and manufactures the finest in children’s interactive educational toys and games. Each product develops and
                                    builds the necessary skills children require for school and beyond
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="grid mgT4">
                        <div className="grid-item1_3">
                            <h5 className="h5 greenText">YORFAN
                                (YOUNG RICE FARMERS
                                ASSOCIATION
                                OF NIGERIA)</h5>
                            <img src={yorfan} alt={'humanity square foundation logo'} className="fluidImg mgT4"/>


                        </div>
                        <div className="grid-item2_3">
                            <p className="h6 whiteText mgT8 textJust">The Young Farmers Association of Nigeria founded in 2021, was created with
                                a core focus of passing knowledge to the Next generation. YORFAN has the
                                mandate to create employment for recent graduates, NYSC members, and
                                generally, the Nigerian youth. Members participate in an intensive training
                                program on rice farming and can gain access to start-up funding from the
                                government and the CBN upon completion.

                                <span className={'display_block'}>
                                    Website: <a href="https://www.kereksuk.com/yorfan" rel="noreferrer" className="link" target={'_blank'} referrerPolicy={'no-referrer'}>www.keresuk.com/yorfan</a>
                              </span>
                            </p>

                        </div>

                    </div>
                    <div className="grid mgT4">
                        <div className="grid-item1_3">
                            <h5 className="h5 greenText">HUMANITY
                                SQUARE FOUNDATION</h5>
                            <img src={humanity_square} alt={'humanity square foundation logo'} className="fluidImg"/>


                        </div>
                        <div className="grid-item2_3">
                            <p className="h6 whiteText textJust">A Non-Governmental organization set up to cater to the needs of indigenes in
                                Northern Nigeria especially ones affected by the horrors of insecurity. The NGO
                                focuses on financing the hospital bills for the injured and the sick in these
                                communities.
                            </p>                             </div>
                    </div>
                    <div className="grid mgT4">
                        <div className="grid-item1_3">
                            <h5 className="h5 greenText">FARM OUT OF
                                POVERTY (FOOP)</h5>

                            <div className="grid">
                                <div className="grid-item2_4">
                                    <img src={Foop} alt="" className="fluidImg mgT2"/>
                                </div>
                                <div className="grid-item2_4">
                                    <img src={Foop2} alt="" className="fluidImg mgT2"/>
                                </div>

                            </div>
                        </div>
                        <div className="grid-item2_3">
                            <p className="h6 whiteText textJust">Founded in 2015, the Farm out of poverty initiative is aimed at training a hundred women every year in rice cultivation with a multiplier effect of five
                                (5). This initiative is focused on empowering rural and smallholder farmers by engaging them in modern farm activities and organizing pieces of
                                training and workshops.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Business;