import React from 'react';
import {FiInstagram,FiLinkedin} from "react-icons/fi";


const Footer = () => {
    return (
        <div className={'opq-card'}>
           <div className="grid- just_btw center-all ">
               <div className="grid-item1_3 ">
                   <p className="h7 lightColor">rw@rotimi-williams.com</p>
               </div>
               <div className="grid-item2_3 display_flex just_end align_center">
                   <a href={'https://www.linkedin.com/in/rotimi-williams-19979393'} referrerPolicy={'no-referrer'} rel="noreferrer" className={'social_icon no-deco'}>
                       <FiLinkedin size={24} className={'lightColor'}/>
                   </a>
                   {/*<Link to={'/'} className={'social_icon no-deco'}>*/}
                   {/*    <FiTwitter size={24} className={'lightColor'}/>*/}
                   {/*</Link>*/}
                   <a target={"_blank"} referrerPolicy={'no-referrer'} rel="noreferrer" href={'https://www.instagram.com/rotimiw/'} className={'social_icon no-deco'}>
                       <FiInstagram size={24} className={'lightColor'}/>
                   </a>
               </div>
           </div>
        </div>
    );
};

export default Footer;