import React from 'react';
import Navigation from "../../components/Navigation";
import aboutText from "../../assets/aboutText.png";
import Rotimi from "../../assets/rotimi.jpg";
import RiceFarm from "../../assets/yorfan-obj.png";
import Footer from "../../components/Footer";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import aboutImg from '../../assets/img_001.png'
const About = () => {
    useScrollToTop()
    return (
        <div className={'about'}>
            <Navigation/>
            <div className="opq-card center-all">
                <h4 className="h5 greenText ">INTRODUCTION</h4>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={aboutImg} className={'fluidImg circular_rad'} alt=""/>
                        <p className="h6 whiteText mgT2 textJust">
                            Globally, one thing every household cannot do without is food. This is a basic need of man
                            for survival. If the essence of farming is to end hunger, then it can be termed profitable.
                            However, like every other venture, money, time and resources must be invested to get high
                            returns.

                        </p>
                        <p className="h6 whiteText mgT2 textJust">
                            Agriculture is one of the oldest civilizations, as old as the existence of man itself! In a world
                            where so many cool and digital careers/activities are being sought after, Rotimi Williams
                            started Kereksuk Rice farms driven by a passion to end world hunger, provide employment
                            opportunities, and change how agriculture is seen in Nigeria and the world at large while
                            making maximum profit. Agriculture can be cool!


                        </p>

                    </div>
                    <div className="grid-item2_4">
                        <p className="h6 whiteText textJust">
                            Farming in Nigeria is one of the most profitable business ventures. Agriculture accounts for
                            over 20% of Nigeria’s GDP with rice being one of the most consumed foods in the world
                            today.
                        </p>

                        <blockquote className={'h7 greenText'}>
                            “Let us not forget that the cultivation of the earth is the most important labor of man. When
                            tillage begins, other arts will follow. The farmers, therefore, are the founders of civilization.”
                            — Daniel Webster.
                        </blockquote>

                        <p className="h6 whiteText mgT2 textJust">
                            Kereksuk rice farm has been in operation for ten years and every milestone and experience
                            has led to the creation of other businesses and ventures aimed at providing solutions to gaps
                            in the agricultural space.
                        </p>

                        <blockquote className={'h7 greenText'}>
                            “Sustainable wealth and businesses revolve around people”
                            - Rotimi Williams
                        </blockquote>
                    </div>
                </div>
                <div className="mgT4 grid">
                    <div className="grid-item2_4">
                        <img src={aboutText} className={'width45'} alt=""/>
                        <img src={RiceFarm} className={'fluidImg'} alt=""/>
                    </div>
                    <div className="grid-item2_4">
                        <h5 className="h5 greenText">BACKGROUND</h5>
                        <p className="h7 whiteText mgT2 textJust">
                            A self-taught and self-made farmer, Rotimi Williams is the founder and CEO of kereksuk rice farms and is passionate about contributing to ending world hunger. Starting off with a banking background, Rotimi quit the financial sector with the sole aim of improving the agricultural sector and contributing to food security.
                        </p>
                        <p className="h7 whiteText mgT2 textJust">
                            Rotimi Williams is an Agripreneur and self-taught farmer who has a passion to make a change
                            in the agricultural space and is actively involved in helping communities affected by the
                            security challenges in Northern Nigeria.
                        </p>
                        <p className="h7 whiteText mgT2 textJust">
                            Growing up, he keenly observed his environment, related with people in his community,
                            understood their struggles, and developed a deep empathy for the hungry, poor, and
                            unemployed. His love for agriculture began after his work in the finance sector and the
                            realization of how under-financed and underutilized agriculture is in Nigeria.


                            Like the basis for most business ventures, he leveraged this gap, starting up the second largest
                            commercial rice farm in Nigeria by land size. His farm, which is situated in Nasarawa state in
                            northern Nigeria, currently sits on 45,000 hectares and employs more than 600 natives of
                            Nasarawa. His empathy for humanity is mirrored in his business ventures as they are linked to
                            socio-economic gaps or needs in communities where his farm is situated and neighboring
                            communities. A huge part of business profits goes into charitable activities. He is married with
                            two beautiful children.  </p>
                    </div>
                </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">

                        <h5 className="h5 greenText">EDUCATION</h5>
                        <p className="h7 whiteText mgT1 textJust">
                            He attended King's College, Lagos for his Secondary Education and moved on to Aberdeen College, obtaining a HNC in Accounting,
                            then proceeded to the University of Aberdeen, Scotland for his university education where he studied Economics. He has a master's
                            degree in Economics from the same institution. He also has a second Master’s Degree in Finance and Development Studies from the
                            School of Oriental and African Studies (SOAS).
                        </p>
                    </div>
                    <div className="grid-item2_4">

                    </div>
                </div>
                <div className="grid mgT4">
                    <div className="grid-item2_4">
                        <img src={Rotimi}  alt="" className={'fluidImgBod'}/>
                    </div>
                    <div className="grid-item2_4">

                        <h5 className="h5 greenText">CAREER & INTERESTS</h5>
                        <p className="h7 whiteText mgT1 textJust">
                            In 2007, after his studies, he became an analyst at the European Economic and Financial Centre in London. He moved on to be an African
                            contributor at Euromoney Magazine in 2008, where he covered the African continent. He worked at First City Monument Bank in 2008 where his
                            concerns for the agricultural sector started growing. He left in 2010 and attempted to create a Structured Trade and Commodity Finance fund. This
                            venture failed, as Rotimi and his partner were unable to raise the seed capital. So he went on to start consulting for farms seeking funds for
                            expansion and it's this journey that led to the creation of Kereksuk Rice Farm in 2012.
                        </p>
                        <p className="h7 whiteText mgT1 textJust">

                            He is also the founder of Resolute 4.0 in partnership with the Nigerian Army, Humanity Square Foundation, Young Farmers Association of Nigeria,
                            and Farm Out of Poverty Initiative. All business ventures are linked and were founded to address gaps in the agricultural space and as a corporate
                            social responsibility.
                            He is a self-taught expressive and illusion artist and has developed several paintings and held art exhibitions.
                            His journey with archery began in 2021 with a need to overcome anxiety and he has gone on to win two medals in Archery Championships. His
                            dream is to coach and train an archery team that can represent Nigeria in the Olympic Games.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default About;